<template>
    <div style="height:635px">
     
  
      <div class="detail" style="margin-left:76px ;" v-if="Number(radio) == 2">
        <div><span>*</span> 邮寄模型：</div>
        <div class="radio-div">
          <div
            :class="{ 'blue-bg': yjmx.includes(1) }"
            @click="yjmxChange(1)"
            style="padding: 0 15px"
          >
            硅橡胶印模
          </div>
          <div :class="{ 'blue-bg': yjmx.includes(2) }" @click="yjmxChange(2)">
            硅橡胶咬𬌗记录
          </div>
        </div>
      </div>
      <div class="detail"   style="margin-left:76px ;" v-if="Number(radio) == 2 && yjmx.length > 0">
        <div><span>*</span> 邮寄方式：</div>
        <div class="radio-div">
          <div :class="{ 'blue-bg': mailType == 1 }" @click="yyqjCLick()">预约取件</div>
          <div :class="{ 'blue-bg': mailType == 2 }" @click="orders(2)">
            自行邮寄
          </div>
        </div>
      </div>
    
   
      <div class="table-box" v-if="radio == 2 && mailType == 1" >
        <!--  v-if="yjmx.length > 0" -->
        <div
          class="blue-btn" :class="{'gray':takeNo&&state!='已取消'}"
          @click="jkdClick"
         
        >
       
          寄快递
        </div>
        <div class="qjxl" v-if="jjxl.jjdz.address">
          <div class="title">{{state }}</div>
          <div class="jjdz">
            <div><span>*</span>寄件地址：</div>
            <div style="width:500px">
              <p>{{ jjxl.jjdz.address }}</p>
              <p>
                {{ jjxl.jjdz.deliveryName }}<span class="space"> </span
                >{{ jjxl.jjdz.contactNumber }}
              </p>
            </div>
          </div>
          <div class="jjdz">
            <div>收件地址：</div>
            <div>
              <p>{{ jjxl.sjdz.address+' '+jjxl.sjdz.deliveryName+' '+ jjxl.sjdz.contactNumber}} </p>
              <p></p>
              <p>
                编号:{{ erpcaseno}} 病例名称:{{this.$store.state.patientName}}<span class="space"> </span
                >
              </p>
            </div>
          </div>
  
          <div class="jjdz">
            <div><span>*</span>预约取件：</div>
            <div>
              <p>{{ jjxl.yyqj }}</p>
            </div>
          </div>
          <div class="jjdz order-btn">
            <div @click="xgdj" class="active" v-if="edit != 1&&state!='已取消'">修改订单</div>
           
          <!--   <div @click="lxjsjy" class="active" v-if="state!='已取消'">联系收件员</div> -->
            <div @click="qxjj()" class="active" v-if="state!='已取消'">取消寄件</div>
           
          </div>
        </div>
      </div>
  
      <div class="table-box" v-if="radio == 2 && mailType == 2">
        <div class="address">
          <div>收件地址：</div>
          <div class="detail-address">
         
            <div>{{ jjxl.sjdz.address+' '+jjxl.sjdz.deliveryName+ ' '+jjxl.sjdz.contactNumber}} </div>

            <div>编号:{{erpcaseno}} {{  ' 病例名称:'+ this.$store.state.patientName }} </div>
          </div>
          <div class="copy-icon" @click="copyAddress"><img :src="imgCopy" alt="" /></div>
        </div>
      </div>
  
      <!-- 弹窗 -->
  
      <!-- 邮寄地址选择 -->
      <newsite @pushdizhishuju="pushdizhishuju" ref="tanchuangdizhi" :id="diz"></newsite>
      <!-- 寄快递 -->
      <el-dialog
        class="jkd"
        title="寄快递"
        :visible.sync="dialogVisible3"
        width="560px"
        :before-close="handleClose"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="dialog-box express" @click="isQjsj = false">
          <div class="code">病例编号：{{ erpcaseno }}</div>
          <div class="text">邮寄硅橡胶模型时，请附病例编号一同寄出。</div>
          <div class="detail" style="flex-shrink: 0;">
            <div><span>*</span> 寄件地址：</div>
  
            <div>
              <div
                @click="clickSelect()"
                class="click-select"
                :class="{ active: selectActive }"
              >
                {{stringStr(dizAddress) }}
              </div>
              <el-select
                ref="myselect"
                v-model="diz"
                @change="selectChange()"
                @blur="selectChange()"
                :disabled="isEdit"
                popper-class="my-select"
              >
                <el-option
                  v-for="(item, i) in dizshuzu"
                  v-if="item.del != 1 || item.id == diz"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!--   <el-button @click="xzdzbut" style="font-size:16px;width:110px;color:#1175d2;" type="text"
                              class="yiy-btn">新增地址
                          </el-button> -->
            </div>
            <div class="edit-box" @click="eidtAddres" v-if="!isEdit">
              <img :src="imgEidt" /> 编辑地址信息
            </div>
          </div>
          <div class="detail">
            <div style="align-self: flex-start;flex-shrink: 0;">收件地址：</div>
  
            <div>
              <div style="width: 370px;">
                {{
                  jjxl.sjdz.address+' '+jjxl.sjdz.deliveryName+' '+jjxl.sjdz.contactNumber
                }}
              </div>
            
            <div>编号:{{erpcaseno}} {{  ' 病例名称:'+ this.$store.state.patientName }} </div>

            </div>
          </div>
          <div class="detail">
            <div><span>*</span> 预约取件：</div>
  
            <div class="qjsj" :class="{ close: isQjsj }" @click.stop="isQjsj = true">
              {{ qjsj.txt }}
              <div class="qjsj-box" v-if="isQjsj">
                <div class="qjsj-day">
                  <div :class="{ active: jt.checked }" @click="checkDay(jt)">
                    {{ jt.txt }}{{ jt.time }}
                  </div>
                  <div :class="{ active: mt.checked }" @click="checkDay(mt)">
                    {{ mt.txt }}{{ mt.time }}
                  </div>
                  <div :class="{ active: ht.checked }" @click="checkDay(ht)">
                    {{ ht.txt }}{{ ht.time }}
                  </div>
                </div>
                <div class="qjsj-line"></div>
                <div class="qjsj-hour">
                  <div
                    v-for="item in hoursList"
                    @click.stop="checkHours(item)"
                    :class="{ active: item.checked }"
                  >
                    <p v-if="item.id != 0">
                      {{ item.start + "~" + item.end }}
                    </p>
                    <p v-if="item.id == 0 && nowH > 7">一小时内</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="submit" @click="submit(3, isEdit)">下单</div>
            <div class="canel" @click="canel(3)">取消</div>
          </div>
        </div>
  
        <!--   <span slot="footer" class="dialog-footer">
                  <div class="submit" @click='submit(3)'>确定</div>
                  <div class="canel" @click='canel(3)'>取消</div>
              </span> -->
      </el-dialog>
      <!-- 3shape -->
      <el-dialog
        title="3Shape"
        :visible.sync="dialogVisible"
        width="560"
        :before-close="handleClose"
        center
      >
        <div class="dialog-box">
          <div class="div-text">如没有您要选择的患者，请导出STL文件，选择本地上传</div>
          <div class="div-text">请选择口扫所在机构：</div>
  
          <el-select v-model="origin" placeholder="选择机构" class="sleect-box">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
  
          <div class="div-text">请添加您的Communicate ID用于获取病例资料：</div>
          <el-input type="text" placeholder="请输入Communicate账号"></el-input>
          <p>Communicate ID查询路径：</p>
          <p>1. 打开3Shape TRIOS软件；</p>
          <p>2. 进入【配置 - 技工所连接 - 配置Communicate】；</p>
          <p>3. 登录名（电邮）即为Communicate ID。</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="submit" @click="submit(1)">确定</div>
          <div class="canel" @click="canel(1)">取消</div>
        </span>
      </el-dialog>
      <el-dialog
        title="3Shape"
        :visible.sync="dialogVisible2"
        width="560"
        :before-close="handleClose"
        center
      >
        <div>
          <div class="search-box">
            <el-input
              placeholder="搜索患者姓名"
              prefix-icon="el-icon-search"
              v-model="search"
            >
            </el-input>
            <div class="btn" @click="bindId()">绑定ID管理</div>
          </div>
          <div class="table">
            <el-table
              :data="tableData2"
              style="width: 100%"
              @row-click="rowClickEv"
              :row-style="isRed"
            >
              <el-table-column prop="upTime" label="上传时间" width="180">
                <template slot-scope="scope">
                  <span class="img-box">
                    <img :src="imgUrl" v-if="scope.row.checked" />
                  </span>
                  {{ scope.row.upTime }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
              <el-table-column prop="urlId" label="所属ID"> </el-table-column>
            </el-table>
          </div>
        </div>
  
        <span slot="footer" class="dialog-footer">
          <div class="submit" @click="submit(2)">确定</div>
          <div class="canel" @click="canel(2)">取消</div>
        </span>
      </el-dialog>
      <el-dialog
        title="联系收件员"
        :visible.sync="dialogVisible5"
        width="200"
        :before-close="handleClose"
        center
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <span sytle="margin-right:10px;">电话</span> {{ empPhone }}
        </div>
  
        <span slot="footer" class="dialog-footer">
          <div class="submit submit-btn" @click="dialogVisible5 = false">确定</div>
        </span>
      </el-dialog>
      <div class="show-box" v-if="isShow">
        <div class="confirm">
          <div class="top"></div>
          <div>
            当前已存在“
            <p>{{ strName.join(",") }}</p>
            ”文件请选择替换或保留两者
          </div>
          <div class="footer">
            <div @click="tihuan">替换已有文件</div>
            <div @click="baoliu">保留两者</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import OSS from "ali-oss";
  import newsite from "@/views/newtangc/editsite";
  
  
  export default {
    components: {
      newsite,
    },
    created() {
       this.erpcaseno=localStorage.getItem('outerNo');
    //    this.casename=localStorage.getItem('patientName')
    
      this.getSjdz();
      this.getTable();
      this.dizhijieko();
      this.timeFormat();
      this.jt.checked=true
      this.checkDay(this.jt)

      this.getSFById();
    
      this.baseURL = this.$http.defaults.baseURL + "/caseInfo/stepEight";
    },
    activated() {
      this.getSjdz();
      this.timeFormat();
      this.jt.checked=true
      this.checkDay(this.jt)
      if(!this.binglft){
     this.getSFById();
    }
     
      this.baseURL = this.$http.defaults.baseURL + "/caseInfo/stepEight";
    },
    props: {
      upCount:{
        type:String
      },
      restartCount:{
        type:String
      },
      phaseid:{
          type:Number
      },
      token: {
        type: Object,
      },
      erpcaseno: {
        type: String,
      },
      binglft:{
         type:Boolean
      },
      radio:{
        type:Number
      },
    },
    data() {
      return {
        nowsj:'',
        casename:'',
         upCOunt:0,
        isFD:true,
        isRstartCount:false,
        selectActive: false,
        strName: [],
        str: [],
        dizAddress: "",
        isShow: false,
        upFileCount:0,
      
        yjmx: [],
        dialogVisible5: false,
        takeNo: "",
        isEdit: false,
        empPhone: "",
        //寄件信息
        jjxl: {
          jjdz: {},
          sjdz: {},
          yyqj: "",
        },
        nowH: "",
        isOpen: true,
        //寄件信息
        caseNo: "",
        caseNoId: "", //寄件单号
        ht: { id: 3, checked: false },
        mt: { id: 2, checked: false },
        jt: { id: 1, checked: false },
        qjsj: {}, //取件时间
        diz: "",
        hoursList: [],
        dizshuzu: [],
        address: {
          province: "天津市",
          city: "天津市",
          area: "东丽区空港经济区",
          address: "中环西路56号中科天保智谷9号楼正丽科技 ",
          deliveryName: "正丽科技 ",
          phone: "15339368485",
        },
        origin: "", //
        selectedArrData: [], // 把选择到的当前行的id存到数组中
        imgEidt: require("@/assets/address_edit.png"),
        imgCopy: require("@/assets/copy.png"),
        imgUrl: require("@/assets/check-circle.png"),
        search: "",
        dialogVisible2: false,
        dialogVisible: false,
        dialogVisible3: false,
        dialogVisible4: false, //邮寄地址选择
        isQjsj: false,
        
        upType: 1, //上传方式
        mailType: 0, //邮寄方式
        tableData: [],
        tableData2: [
          {
            id: 1,
            upTime: "2022.08.12",
            name: "李尔",
            urlId: "magicalign.com",
            checked: false,
          },
          {
            id: 2,
            upTime: "2022.08.12",
            name: "李尔2",
            urlId: "magicalign.com",
            checked: false,
          },
        ],
        uplist: [],
        newImg: require("@/assets/2.2病例详情/newfile.png"),
        fileImg: require("@/assets/2.2病例详情/file.png"),
        // 是否是新建,true不是,false是
  
        isNew: true,
        fileList: [],
        // 是否上传成功
        flag: true,
        OK: require("@/assets/3新建病例/上传文件.png"), //已上传
        NO: require("@/assets/3新建病例/PC-新建病例上传文件.png"), //未上传
        type: "",
        scwjadd: "Y",
        baseURL: "", // 上传文件路径默认地址
        newArr: {},
      };
    },
    methods: {
      upSuccess(){
          console.log('zzzzzz')
      },
       yyqjCLick(){
        this.mailType=1
        if(!this.binglft||this.isRstartCount){
          this.getSFById(false,true);
        }
        
       },
      jkdClick(){
          if(!this.takeNo||this.state=='已取消'){
            this.dizAddress=''
            this.diz=''
            this.qjsj.txt=''
            this.jt.checked=true
            this.mt.checked=''
            this.ht.checked=''
            this.hoursList.filter(item=>{
                  item.checked=false
            })
            this.dialogVisible3 = true
             this.isEdit = false
          }
      },
        stringStr(dizAddress){
          let str=''
           if(dizAddress&&dizAddress.length>53){
              str=dizAddress.substr(0,53)+'...'
           }else{
           str= dizAddress
           }
            return  str
        },
      upChange() {
        if (this.radio == 2&&(!this.binglft)||this.isRstartCount) {
          this.getSFById();
        }
      },
      selectChange() {
         
            this.selectActive = false;
        this.dizshuzu.filter((item) => {
          console.log(item, this.diz);
          if (item.id == this.diz) {
            this.dizAddress = item.name;
          }
        });
         
        
      },
      clickSelect() {
          if(this.isEdit){
              return
          }
        if(!this.selectActive){
        this.$refs.myselect.$el.click();
        this.selectActive = true;
      }
      },
      getScan() {
        let url='/caseInfo/getStepEightScan'
        if(this.binglft){
          url='/caseInfo/getStepEightScanRandom'
        }
        this.$http({
          url,
          method: "get",
          params: {
            caseid: this.$store.state.caseId + "",
            stageName: this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId+$store.stage.isAdd,
            phaseid:this.binglft?this.phaseid+1:this.phaseid,
            uuid:this.binglft?this.upCount:''
          },
        })
          .then(({ data }) => {
            data = JSON.parse(data.data);
  
            this.fileList = [];
            // console.log(data);
            data.forEach((item) => {
              console.log(item);
              if (item.filenumber == "12") {
                // console.log(item);
                this.fileList.push({
                  name: item.filename,
                  url: item.ossfilepath,
                  id: item.casedataid,
                });
                this.flag = false;
                this.scwjadd = "N";
              }
            });
          })
          .catch((err) => {});
      },
  
      yjmxChange(val) {
        if (this.yjmx.indexOf(val) != -1) {
          this.yjmx.splice(this.yjmx.indexOf(val), 1);
        } else {
          this.yjmx.push(val);
        }
        if (this.yjmx.length == 0) {
          this.mailType = 0;
            console.log('xxxxx')
        }
      },
      setupFileList() {
        this.$emit("setupFileList", this.fileList);
      },
      lxjsjy() {
        this.dialogVisible5 = true;
      },
      getSFById(isflg,val) {
      this.isRstartCount=true;
      this.state="";
      this.takeNo=""
        this.$set(this.jjxl.jjdz, "address", "");
        this.$set(this.jjxl.jjdz, "contactNumber", "");
        this.$set(this.jjxl.jjdz, "deliveryName", "");
  
        this.$set(this.jjxl, "yyqj", "");
  
     
  
  
        this.edit = 1;
        this.cancel = 1;
        this.state = "";
  
         let data = { caseIds: this.$store.state.caseId, mailingMethod: this.mailType }; //病例id
        this.$http({
          url: "/webCase/opGetSFById",
          method: "post",
          dataType: "json",
          data: data,
          contentType: "application/x-www-form-urlencoded",
        })
          .then(({ data }) => {
            this.isOpen = true;
            this.LoadingClose();
            if (data.code == 200) {
              if (isflg) {
                this.dialogVisible3 = false;
                this.$message({
                  message: "下单成功",
                  type: "success",
                  duration: 1500,
                });
              }
  
              let res = JSON.parse(data.data);
             
              if (res != null) {
                this.state = res.orderState;
                this.edit = res.edit;
                this.cancel = res.cancel;
  
                this.caseNoId = res.orderNo;
                let sj = "";
                /*  * 寄件地址：
                         中国上海上海闵行万金中心10F
    
                         海波13641636968
    
                         收件地址：
                         中环西路86号中科天保智谷9号楼
    
                         正丽科技质检部15890128745
    
                             * 预约取件：
                         今天(2022.12.21) 18: 00~19: 00 */
  
                if (res.mailingModel == 1) {
                  this.yjmx = [1];
                } else if (res.mailingModel == 3) {
                  this.yjmx = [1, 2];
                } else if (res.mailingModel == 2) {
                  this.yjmx = [2];
                }
      
                
                if (res.mailingMethod) {
                  this.mailType = Number(res.mailingMethod);
                }
                if(val){
                    this.mailType=1
                }
                if (res.orderState != "1") {
                  let time = new Date();
  
                  time = new Date(res.bookingTime);
                   this.nowsj=this.addZero(time.getHours())+":"+this.addZero(time.getMinutes())
                
            

                  let time2 = new Date(time.getTime() + 1000 * 60 * 60);
                  let jt = new Date();
                  let Y = jt.getFullYear();
                  let M = jt.getMonth() + 1;
                  let D = jt.getDate();
  
                  let mt = new Date(
                    new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                      24 * 3600 * 1000
                  );
                  let ht = new Date(
                    new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                      48 * 3600 * 1000
                  );
                
                  
                  let rq = "";
                  if (
                    time.getDate() == new Date().getDate() &&
                    time.getMonth() == new Date().getMonth()
                  ) {
                    rq = "(今天)";
                  
                       
                    if (time.getHours() < 20) {
                      time2 = new Date(time.getTime() + 1000 * 60 * 60);
                    } else {
                      time2 = new Date(time.getTime() + 1000 * 60 * 30);
                    }
                    sj =
                      `(${rq}) ` +
                      time.getFullYear() +
                      "-" +
                      this.addZero(time.getMonth() + 1) +
                      "-" +
                      this.addZero(time.getDate()) +
                      ` 
                    ${
                      this.addZero(time.getHours()) +
                      ":" +
                      this.addZero(  time.getMinutes()) +
                      ":" +
                      this.addZero(time.getSeconds())
                    } ~ 
                    ${
                      this.addZero( this.addZero(time2.getHours())) +
                      ":" +
                      this.addZero( time2.getMinutes()) +
                      ":" +
                      this.addZero(time2.getSeconds())
                    } `;
                  } else if (time.getDate() == mt.getDate() &&
                  time.getMonth() ==mt.getMonth()) {
                    rq = "(明天)";
                 

                    if (time.getHours() < 20) {
                      time2 = new Date(time.getTime() + 25 * 1000 * 60 * 60);
                    } else {
                      time2 = new Date(
                        time.getTime() + 24 * 1000 * 60 * 60 + 60 * 1000 * 30
                      );
                    }
                
                  
                  } else {
                    if( time.getDate() ==ht.getDate() &&
                    ht.getMonth() == time.getMonth()){
                        rq = "(后天)";

                    }
                   


                    if (time.getHours() < 20) {
                      time2 = new Date(time.getTime() + 49 * 1000 * 60 * 60);
                    } else {
                      time2 = new Date(
                        time.getTime() + 48 * 1000 * 60 * 60 + 60 * 1000 * 30
                      );
                    }}
  
                    sj =
                      `${rq} ` +
                      time.getFullYear() +
                      "-" +
                      this.addZero(time.getMonth() + 1) +
                      "-" +
                      this.addZero(time.getDate()) +
                      ` 
                    ${
                      this.addZero(time.getHours()) +
                      ":" +
                      this.addZero( time.getMinutes()) +
                      ":" +
                      this.addZero(time.getSeconds())
                    } ~ 
                    ${
                      this.addZero(time2.getHours()) +
                      ":" +
                      this.addZero( time2.getMinutes()) +
                      ":" +
                      this.addZero(time2.getSeconds())
                    } `;
                  
               
                  // }
                  // this.$set( this.jjxl,'yyqj',  '')
                  this.empPhone = res.empPhone;
                  this.takeNo = res.takeNo;
                     //   console.log(this.dizshuzu);
                  this.dizshuzu.filter((item) => {
                    console.log(item.id, res.addressId);
                    if (
                      item.id == res.addressId ||
                      item.id == res.sendAddressIdWeb
                    ) {
                      this.$set(
                        this.jjxl.jjdz,
                        "address",
                        item.country + item.province + item.city + item.area + item.address
                      );
  
                      this.$set(this.jjxl.jjdz, "contactNumber", item.contactNumber);
                      this.$set(this.jjxl.jjdz, "deliveryName", item.deliveryName);
                      this.$set(this.jjxl, "yyqj", sj);
                    }
                  });
                }
              }
            }
          })
          .catch((err) => {
            this.isOpen = true;
  
            console.log(err);
          });
      },
      xgdj() {
      
       this.checkDay(this.jt)
         
    
         this.dizAddress=this.jjxl.jjdz.address 
         this.dizshuzu.filter((item) => {
         
         if (item.name == this.dizAddress) {
           this.diz = item.id;
         }
       });
        this.isEdit = true;
  
        this.dialogVisible3 = true;
      },
      //收件地址
      getSjdz() {
        this.$http({
          url: "/webCase/optoAddress",
          method: "post",
        })
          .then(({ data }) => {
            this.isOpen = true;
  
            if (data.code == 200) {
              let res = JSON.parse(data.data);
              // console.log(res);
              this.jjxl.sjdz.address = res.address;
              this.jjxl.sjdz.city = res.city;
              this.jjxl.sjdz.province = res.province;
              this.jjxl.sjdz.area = res.county;
              this.jjxl.sjdz.contactNumber = res.tel;
              this.jjxl.sjdz.deliveryName = res.contact;
              // console.log(this.jjxl.sjdz);
            }
          })
          .catch((err) => {
            this.isOpen = true;
  
            console.log(err);
          });
      },
      qxjj() {
        if (this.isOpen) {
          this.isOpen = false;
         
          this.$confirm("你是否要取消寄件", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$http({
                url: "/webCase/opCancelOrder",
                method: "post",
                data: {
                  dealType: 2,
                  language: "zh_CN",
                  orderId: this.caseNoId,
                },
              })
                .then(({ data }) => {
                  this.isOpen = true;
  
                  if (data.code == 200) {
                    this.getSFById();
                    this.$message({
                      message: "取消寄件成功",
                      type: "success",
                      duration: 1500,
                    });
                  }
                })
                .catch((err) => {
                  this.isOpen = true;
  
                  console.log(err);
                });
            })
            .catch((err) => {
              this.isOpen = true;
              return;
            });
        }
      },
  
      checkHours(val) {
        console.log(val);
        this.hoursList = this.hoursList.filter((item) => {
          if (val.id == item.id) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return true;
        });
        this.qjsj.txt =
          val.txt +
          " (" +
          val.time +
          ") " +
          (val.id == 0 ? "一小时内" : val.start + "~" + val.end);
        this.qjsj.dayId = val.dayId;
        this.qjsj.hoursId = val.id;
        val.id == 0
          ? (this.qjsj.time =
              val.formatTime +
              ` ${
                new Date().getHours() < 10
                  ? "0" + new Date().getHours()
                  : new Date().getHours()
              }:` +
              `${
                new Date().getMinutes() < 10
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()
              }:` +
              `${
                new Date().getSeconds() < 10
                  ? "0" + new Date().getSeconds()
                  : new Date().getSeconds()
              }`)
          : (this.qjsj.time = val.formatTime + " " + val.start + ":00");
  
        this.isQjsj = false;
      },
      checkDay(val) {
        this.jt.checked = false;
        this.mt.checked = false;
        this.ht.checked = false;
        val.checked = true;
  
        this.hoursList = [];
        if (val.h) {
          var h = new Date().getHours();
          this.nowH = h;
          h < 8 ? (h = 8) : h;
          var m = new Date().getMinutes();
          if (h > 20 || (h == 20 && m > 30)) {
            return;
          }
          console.log(val);
          this.hoursList.push({
            dayId: val.id,
            id: 0,
            txt: val.txt,
            time: val.time,
            formatTime: val.formatTime,
            checked: false,
          });
          for (var i = h; i < 20; i++) {
            this.hoursList.push({
              dayId: val.id,
              id: i,
              time: val.time,
              formatTime: val.formatTime,
              txt: val.txt,
              checked: false,
              start: (i < 10 ? "0" + i : i) + ":00",
              end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
            });
          }
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: "20:30",
          });
        } else {
          for (var i = 8; i < 20; i++) {
            this.hoursList.push({
              dayId: val.id,
              id: i,
              time: val.time,
              formatTime: val.formatTime,
              txt: val.txt,
              checked: false,
              start: (i < 10 ? "0" + i : i) + ":00",
              end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
            });
          }
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: "20:30",
          });
        }
      },
      timeFormat() {
        let time = new Date();
        let y = time.getFullYear();
        let m = this.addZero(time.getMonth() + 1);
        let d = this.addZero(time.getDate());
        let h = time.getHours();
        this.jt.txt = "今天";
        this.jt.time = `${y}.${m}.${d}`;
        this.jt.formatTime = `${y}-${m}-${d}`;
        this.jt.h = h;
        time = new Date(new Date().getTime() + 3600000 * 24);
        y = time.getFullYear();
        m = this.addZero(time.getMonth() + 1);
        d = this.addZero(time.getDate());
        h = time.getHours();
        this.mt.txt = "明天";
        this.mt.time = ` ${y}.${m}.${d}`;
        this.mt.formatTime = `${y}-${m}-${d}`;
        time = new Date(new Date().getTime() + 3600000 * 24 * 2);
        y = time.getFullYear();
        m = this.addZero(time.getMonth() + 1);
        d = this.addZero(time.getDate());
        h = time.getHours();
        this.ht.txt = "后天";
  
        this.ht.time = ` ${y}.${m}.${d}`;
        this.ht.formatTime = `${y}-${m}-${d}`;
      },
      addZero(val) {
        if (val < 10) {
          return "0" + val;
        } else {
          return val;
        }
      },
      copyAddress() {
        let copyInput = document.createElement("input"); //创建input元素
        document.body.appendChild(copyInput); //向页面底部追加输入框
        copyInput.setAttribute(
          "value",
         
            this.jjxl.sjdz.address +
            this.jjxl.sjdz.deliveryName +
            this.jjxl.sjdz.contactNumber+'('+this.$store.state.patientName+'-'+this.erpcaseno+')'
        ); //添加属性，将url赋值给input元素的value属性
        copyInput.select(); //选择input元素
        document.execCommand("Copy"); //执行复制命令
        this.$message.success("地址复制成功"); //弹出提示信息，不同组件可能存在写法不同
        //复制之后再删除元素，否则无法成功赋值
        copyInput.remove(); //删除动态创建的节点
      },
      eidtAddres() {
      if(this.diz){
        this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
       }else{
        this.$message({ message: "请选择地址", type: "error" });
          
       }
       },
      // 新增地址方法
      pushdizhishuju() {
        this.dizhijieko();
        // this.form.diz =
        // this.$refs.tanchuangdizhi.country +
        // this.$refs.tanchuangdizhi.province +
        // this.$refs.tanchuangdizhi.city +
        // this.$refs.tanchuangdizhi.area +
        // this.$refs.tanchuangdizhi.form.diz;
        // this.dizshuzu.push(this.form.diz)
      },
      // 收货收获地址列表
      dizhijieko() {
        this.$http({
          url: "/deliveryAddress/getAddressList",
          method: "get",
          params: {},
        })
          .then(({ data }) => {
            if (data.code == 200) {
              this.dizshuzu = JSON.parse(data.data).map((x) => {
                return {
                  name:
                    x.country +
                    x.province +
                    x.city +
                    x.area +
                    x.address +
                    " - " +
                    x.deliveryName,
                  id: x.id.toString(),
                  country: x.country,
                  province: x.province,
                  city: x.city,
                  area: x.area,
                  address: x.address,
                  deliveryName: x.deliveryName,
                  contactNumber: x.contactNumber,
                  orthoPlusId: x.orthoPlusId,
                  del: x.del,
                };
              });
                if(this.diz){
                     this.dizshuzu.filter(item=>{
                        if(item.id==this.diz){
                            this.dizAddress=item.name
                        }
                     })
                }
              ////   console.log(this.dizshuzu);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      upFile() {
        this.dialogVisible = true;
      },
      submit(val, isEdit) {
        if (val == 1) {
          this.dialogVisible = false;
          this.dialogVisible2 = true;
        } else if (val == 3 && !isEdit) {
          this.orders();
        } else if (val == 3 && isEdit) {
          this.upOrder();
        } else {
          this.dialogVisible2 = false;
        }
      },
      upOrder() {
        //    console.log(this.qjsj);
        let time = this.qjsj.time.split(" ");
  
        let params = {
          cancel: false,
          deliverDate: time[0],
          deliverTimeMin: time[1],
          waybillNo: this.takeNo,
          serviceCode: 7,
        };
  
        this.$http({
          url: "/webCase/EditOrders",
          method: "POST",
          data: params,
        })
          .then(({ data }) => {
            this.$message({ message: "修改成功", type: "success", duration: 1500 });
            this.dialogVisible3 = false;
  
            this.getSFById();
          })
          .catch((err) => {
            this.isOpen = true;
  
            console.log(err);
          });
      },
       canelOrder(val){
  
       },
       openOrder(val){
        let params = {};
        val == 2 ? (this.mailType = 2) : "";
        if (!this.diz && val != 2) {
          this.$message({ message: "请填写寄件地址", type: "error", duration: 1500 });
          return;
        }
        if (!this.qjsj.txt && val != 2) {
          this.$message({ message: "请选择预约取件时间", type: "error", duration: 1500 });
          return;
        }
        if (this.isOpen) {
          this.isOpen = false;
          let address = this.dizshuzu.filter((item) => {
            if (item.id == this.diz) {
              return item;
            }
          })[0];
  
          let time = new Date();
          let mailingModel = 0;
          this.yjmx.filter((item) => {
            mailingModel += item;
          });
          if (val != 2) {
            params = {
                casename:this.$store.state.patientName,//病例名称
              caseId: this.$store.state.caseId, //病例id
              caseNo: this.erpcaseno, //病例编号
              sendAddressIdWeb: address.id, //地址id
              // recv_address:address.address,//收件人地址
              sendAddressIdOrtho: address.orthoPlusId, // orthID
              mailingModel: mailingModel,
              mailingMethod: Number(this.mailType),
  
              orderId:
                this.erpcaseno +
                time.getFullYear() +
                (time.getMonth() + 1) +
                time.getDate() +
                time.getHours() +
                time.getMinutes() +
                time.getSeconds(), //病例编号
                phone:address.contactNumber,
                contactInfoList: [
                {
                
                  address: address.address,
                  city: address.city,
                  county: address.area,
                  province: address.province,
  
                  contact: address.deliveryName,
                  contactType: 1,
                  country: "CN",
                  tel: address.contactNumber,
                },
              ],
              expressTypeId: 2, //标准快速
              sendStartTm: this.qjsj.time, // 取件时间
            };
          } else {
            
            if (this.caseNoId) {
        
             this.$http({
                url: "/webCase/opCancelOrder",
                method: "post",
                data: {
                  dealType: 2,
                  language: "zh_CN",
                  orderId: this.caseNoId,
                },
               })
                .then(({ data }) => {
                  if (data.code == 200) {
                    this.LoadingClose();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              }
              params = {
              caseId: this.$store.state.caseId, //病例id
              caseNo: this.erpcaseno, //病例编号
              mailingModel: mailingModel,
              mailingMethod: 2,
            };
            }
          
            
          this.$http({
            url: "/webCase/opCallSFOrder",
            method: "POST",
            data: params,
          })
            .then(({ data }) => {
              this.isOpen = true;
  
              this.jjxl.yyqj = this.qjsj.txt;
              this.caseNoId = params.orderId;
              this.LoadingOpen();
              if (val != 2) {
                setTimeout(() => {
                  this.getSFById(true);
                }, 5000);
              } else {
                   this.LoadingClose()
               /*  this.getSFById(); */
              }
            })
            .catch((err) => {
              this.isOpen = true;
  
              console.log(err);
            });
          
            
      
      }
       },
      //下单
      orders(val) {
        console.log(this.mailType,val)
        if(this.mailType==2&&val==2){
            return
        }
        if(this.state&&val==2&&this.state!='已取消'&&this.state!='自行邮寄已下单'){
          this.$confirm("您已预约上门取件，选择自行邮寄将取消预约上门，请确认是否自行邮寄", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
               this.openOrder(val)
            })
        }else{
          if(val==2){
          this.$confirm("请确认是否自行邮寄", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
               this.openOrder(val)})
        }else{
          this.openOrder(val)


        }
      }
    },
      canel(val) {
        val == 1 ? (this.dialogVisible = false) : (this.dialogVisible2 = false);
        switch (val) {
          case 1:
            this.dialogVisible = false;
            break;
          case 2:
            this.dialogVisible2 = false;
            break;
          case 3:
            this.dialogVisible3 = false;
            this.isEdit = false;
            break;
        }
      },
      // 某一行被点击行触发事件，默认形参代表一整行数据
      rowClickEv(row) {
        console.log(row); //点击的那行数据默认是对象{__ob__: Observer}，将其转数组
        row.checked = !row.checked;
        if (row.checked) {
          this.selectedArrData.push(row);
        } else {
          this.selectedArrData = this.selectedArrData.filter((item) => {
            if (item.id != row.id) {
              return true;
            }
          });
        }
      },
  
      isRed({ row }) {
        const checkIdList = this.selectedArrData.map((item) => item.id);
        if (checkIdList.includes(row.id)) {
          return {
            backgroundColor: "#1275D2",
            color: "#FFFFFF",
          };
        }
      },
      handleCurrentChange(val) {
        this.tableData2 = this.tableData2.filter((item) => {
          if (val.id == item.id) {
            item.checked = !item.checked;
          }
          return true;
        });
        console.log(this.tableData2);
      },
      bindId() {},
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then(() => {
            this.isEdit = false;
            done();
          })
          .catch(() => {});
      },
      delFn(id) {
        console.log(id);
        this.tableData = this.tableData.filter((item) => {
          if (item.id == id) {
            return false;
          } else {
            return true;
          }
        });
      },
      getTable() {
        this.tableData = [
          {
            id: 1,
            upTime: "2022.08.12",
            name: "李尔",
            urlId: "magicalign.com",
          },
          {
            id: 2,
            upTime: "2022.08.12",
            name: "李尔2",
            urlId: "magicalign.com",
          },
        ];
      },
      // =======================\\
      schuawj() {
        this.$refs.schuanwj.submit();
      },
      tihuan() {
       
        for (let i = 0; i < this.str.length; i++) {
          if (this.str[i].name) {
            for (var j = 0; j < this.fileList.length; j++) {
              if (this.fileList[j].name == this.str[i].name) {
                this.fileList.splice(j, 1);
                break;
              }
            }
            for (var j = 0; j < this.fileList.length; j++) {
              if (this.fileList[j].name == this.str[i].name) {
                this.fileList[j].casedataid = this.str[i].casedataid;
                this.fileList[j].update = true;
                break;
              }
            }
          }
        }
        this.upFileCount=0
        this.fileList.filter((item) => {
                if (!item.casedataid || item.update) {
                  this.upFileCount++;
                }
              });
                
          this.baocunUpFile(this.fileList)
        this.isShow = false;
      },
      baoliu() {
        let obj = {};
  
        for (let j = 0; j < this.fileList.length; j++) {
          let gs = this.fileList[j].name.substr(
            this.fileList[j].name.lastIndexOf("."),
            this.fileList[j].name.length
          );
          let name = this.fileList[j].name.substr(
            0,
            this.fileList[j].name.lastIndexOf(".")
          );
  
          for (let i = 0; i < this.fileList.length; i++) {
            let fileGs = this.fileList[i].name.substr(
              this.fileList[i].name.lastIndexOf("."),
              this.fileList[i].name.length
            );
            let fileName = "";
            if (gs == fileGs) {
              if (this.fileList[i].name.indexOf(/([0-9])/) != -1) {
                fileName = this.fileList[i].name.substr(
                  0,
                  this.fileList[i].name.lastIndexOf(/([0-9])/)
                );
              } else {
                fileName = this.fileList[i].name.substr(
                  0,
                  this.fileList[i].name.lastIndexOf(".")
                );
              }
  
              if (fileName == name) {
                console.log(obj, obj[name], name);
                if (obj[name] != undefined) {
                  this.fileList[i].name = fileName + "(" + obj[name]++ + ")" + gs;
                } else {
                  obj[name] = 1;
                }
              }
            }
          }
        }
        this.upFileCount=0  
        this.fileList.filter((item) => {
                if (!item.casedataid || item.update) {
                  this.upFileCount++;
                }
              });
        
        this.baocunUpFile(this.fileList)
  
        this.isShow = false;
      },
       ifRepeat(fileList){
        this.str = [];
        this.strName = [];
        for (let i = 0; i < fileList.length; i++) {
          for (let j = 0; j < fileList.length; j++) {
            if (i != j && fileList[i].name == fileList[j].name) {
              if (!this.str.includes(fileList[i].name)) {
                this.str.push(fileList[i]);
                this.str.push(fileList[i].name);
                this.strName.push(fileList[i].name);
              }
            }
          }
        }
        if (this.str.length > 0) {
          this.fileList=fileList
          this.isShow = true;
          return  false
        }else{
           return  true
        }
       },
      // 判断文件格式
      ifType(file, fileList) {
        const fileExt = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
  
        const isLt2M = file.raw.size / 1024 / 1024 < 100;
  
        if (!isLt2M) {
          this.$message.error("上传文件最大100MB !");
          return false;
        }
  
     
        return true;
      },
      // 改变事件
      // 删除文件
      removeFile(uid) {
        this.$http({
          url: "/caseInfo/delScan",
          method: "POST",
          data: {
            caseId: this.$store.state.caseId,
               // 病例阶段
               stageName: this.binglft ? 2 : this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId+this.$store.state.isAdd,
            fileNumber: "12",
  
            fileId: uid,
          },
        })
          .then(({ data }) => {
            this.$message({ message: "删除成功", type: "success", duration: 1500 });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 改变事件
      handleSuccess(file, fileList) {
         
       
        if (!this.ifType(file, fileList)) {
          fileList.pop();
  
          return;
        }
         if(!this.ifRepeat(fileList)){
         return
         }
  
        this.file = file;
       
       
         setTimeout(() => {
          if(this.isFD){
         this.baocunUpFile(fileList) 
           this.isFD=false 
        }
         }, 500);
        //需要上传的文件
  
        this.scwjadd = "N"; //是否新建
        this.flag = false;
      },
      //保存上传文件
      baocunUpFile(fileList) {
         this.LoadingOpen()
      
          this.uplist = [];
          this.fileList = fileList;
        this.upLoadFile(this.fileList)
     
     
      },
      async upLoadFile(fileList) {
      
      
         
  
      
          var client = new OSS({
            region: "oss-" + this.token.ossAddress, // oss地址
            accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
            accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
            stsToken: this.token.securityToken,
            bucket: this.token.bucketName, // 仓库名字
            useFetch: true, // 支持上传大于100KB的文件
            // secure:true // 返回的url为https
          }); 
          let stageName = this.$store.state.stageNameId;
            let stageCount=this.$store.state.stageCountId
            let stage=''
             fileList.filter(item=>{
   
             
          
         
            if (stageName == 1||stageCount==1) {
              stage = "New";
            }else{
                stage="Restart"+stageCount;
            }
         
            
            var filePath =
               this.$store.state.caseId +
              "/ScanFile/" +
              stage +
              "/" +
              (this.phaseid+1)+'/'+
              item.name.substr(0,item.name.indexOf(".")) +
              "_" +
              new Date().getTime() +
              item.name.substr(item.name.indexOf("."));
              item.filePath=filePath
              item.phaseid=this.binglft?this.phaseid+1:this.phaseid 
  
  
          })
           options
          for (const item of fileList) {
             if(!item.casedataid||item.update){
      const result = await client.multipartUpload( item.filePath,item.raw);
  
       this.uplist.push({
        update:item.update,
        casedataid:item.casedataid?item.casedataid:'',
        caseid:this.$store.state.caseId,
  fileNumber: "12",
  fileType: "S",
  filename:item.name,
  ossfileName: result.name.substr(result.name.lastIndexOf('_')+1,result.name.length),
  ossfilePath:result.name,
  phaseid:this.binglft?this.phaseid+1:this.phaseid,
       })
    }else{
      item.ossfilePath=item.filePath
      this.uplist.push(item)
    }
  }
      this.upFileUrl();
      },
      upFileUrl() {
       
  
        let CaseScan = {};
        let url='/caseInfo/stepEightScan'
        if(this.binglft){
          url='/caseInfo/stepEightScanRandom'
        }
          console.log(this.$store.state.isAdd)
        this.$http({
          url,
          method: "POST",
          data: {
            caseId: this.$store.state.caseId,
            // 病例阶段
            stageName: this.binglft ? 2 : this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId+this.$store.state.isAdd,
            add: "N",
            ossFiles: this.uplist,
            restartCount: +this.$store.state.isAdd?this.$store.state.stageCountId:this.$store.state.stageCountId-1,
            phaseid:this.binglft?this.phaseid+1:this.phaseid,
           uuid:this.binglft?this.upCount:''
          },
        })
          .then(({ data }) => {
          
            this.isFD=true
  
            this.getScan()
          })
          .catch((err) => {
           
            this.LoadingClose()
            this.$message({ message: "保存失败上传失败", type: "error", duration: 1500 });
            console.log(err);
          });
      },
      // 删除文件
      remove(val) {
        this.$confirm("你是否要删除文件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.fileList = this.fileList.filter((item) => {
              if (item.uid != val.uid) {
                return true;
              }
              if (val.id) {
                this.removeFile(val.id);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            return;
          });
      },
      
     
     
      getScan() {
        let url='/caseInfo/getStepEightScan'
        if(this.binglft){
          url='/caseInfo/getStepEightScanRandom'
        }
        this.$http({
          url,
          method: "get",
          params: {
            caseid: this.$store.state.caseId + "",
            stageName: this.binglft ? 2 : this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId+this.$store.state.isAdd,
            uuid:this.binglft?this.upCount:'' 
          },
        })
          .then(({ data }) => {
            data = JSON.parse(data.data);
  
            this.fileList = [];
            // console.log(data);
            data.forEach((item) => {
              //  console.log(item);
              if (item.filenumber == "12") {
                //  console.log(item);
  
                // console.log(item.caseid,item.filenumber,item.casedatatype,item.filename);
                this.fileList.push({
                  ossfilename: item.ossfilename,
                  name: item.filename,
                  url: item.ossfilepath,
                  filePath: item.ossfilepath,
                  id: item.casedataid,
                  casedataid: item.casedataid,
                  filename: item.filename,
                  fileType: item.casedatatype,
                  caseid: item.caseid,
                  fileNumber: "12",
                  restartType: 1,
                });
                this.flag = false;
                this.scwjadd = "N";
              }
            });
            this.LoadingClose()
  
          })
          .catch((err) => {
            this.LoadingClose()
  
            console.log(err);
          });
      },
      deleteimg() {
        // 删除 图片 接口
        this.$http({
          url: "/caseInfo/delScan",
          method: "POST",
          data: {
            // 病例 id
            caseId: this.$store.state.binglId,
            // 病例阶段
               // 病例阶段
               stageName: this.binglft ? 2 : this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId+this.$store.state.isAdd,
            // 文件序号
            fileNumber: 12,
          },
        })
          .then(({ data }) => {})
          .catch((err) => {
            console.log(err);
          });
      },
    },
    watch: {
      fileList: function () {
        this.setupFileList();
      },
    },
  };
  </script>
  <style scoped lang="less">
  .footer {
    margin-top: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .space {
    margin-right: 10px;
  }
  
  .qjxl {
    .title {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  
    span,
    .title {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ff0000;
    }
  
    .jjdz {
      display: flex;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #666666;
      margin-bottom: 16px;
  
      .space {
        margin-left: 10px;
      }
      
      & > div:first-of-type {
        width: 90px;
      }
    }
  
    .order-btn {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
  
      .active {
        color: #1275d2;
        text-decoration: underline;
      }
      & > div {
        width: auto !important;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  
  .qjsj {
    padding-left: 12px;
    width: 300px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #1275d2;
    position: relative;
  
    &::after {
      content: "";
      position: absolute;
      border: 8px solid transparent;
      border-top: 8px solid #1275d2;
      right: 9px;
      top: 40%;
      //transform: translateY(-50%);
    }
  
    .qjsj-box {
      left: 0;
      width: 560px;
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      position: absolute;
      background: #ffffff;
      top: 42px;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
  
      .qjsj-day {
        padding: 16px 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
  
        div {
          cursor: pointer;
          width: 160px;
          height: 28px;
          font-size: 14px;
          border: 1px solid #1275d2;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        .active {
          background: #1275d2;
          color: #ffffff;
        }
      }
  
      .qjsj-line {
        margin: auto;
        width: 540px;
        height: 1px;
        background: #999999;
      }
  
      .qjsj-hour {
        margin-right: -18px;
        flex-shrink: 1;
        overflow-y: scroll;
        display: flex;
        padding-left: 16px;
        align-items: center;
        flex-wrap: wrap;
  
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-top: 16px;
          margin-bottom: 16px;
          margin-right: 18px;
          width: 90px;
          height: 24px;
          border-radius: 12px;
          border: 1px solid #1275d2;
          display: flex;
          justify-items: center;
          align-items: center;
          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
  
          p {
            margin-top: 0;
          }
        }
  
        div:nth-of-type(5n) {
          margin-right: 0;
        }
  
        .active {
          background: #1275d2;
          color: #ffffff;
        }
      }
    }
  }
  
  .close::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  
  .click-select {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-right: 35px;
    background: white;
    z-index: 10;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid #1275d2;
    &.active::after {
      top: 19%;
      border: 8px solid transparent;
      border-bottom: 8px solid #1275d2;
    }
    &::after {
      content: "";
      position: absolute;
      border: 8px solid transparent;
      border-top: 8px solid #1275d2;
      right: 9px;
      top: 40%;
    }
  }
  /deep/ .express {
    height: 448px;
  
    .el-input__inner {
      margin-left: 1px;
      margin-top: 1px;
      width: 300px;
      height: 64px;
      border-radius: 6px;
      border: transparent;
      color: transparent;
    }
  
    .edit-box {
      cursor: pointer;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      display: flex;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
    }
  
    .code {
      margin-top: 32px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #0075f6;
    }
  
    .detail {
      align-self: flex-start;
      margin-top: 32px;
      margin-left: 0;
    }
  
    .text {
      margin-top: 2px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #666666;
    }
  }
  
  .copy-icon {
    cursor: pointer;
    align-self: end;
  }
  
  .address {
    margin-top: 32px;
    display: flex;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
  
  /deep/ .tangcform {
    padding-top: 20px;
  }
  .gray{
      background: gray !important;
  }
  .blue-btn {
    cursor: pointer;
    width: 96px;
    height: 28px;
    background: #1275d2;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
  }
  
  .img-box {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 9px;
  
    img {
      width: 100%;
      display: block;
    }
  }
  
  .table {
    /deep/ th {
      border-bottom: #1275d2 2px solid;
    }
  
    /deep/ td {
      cursor: pointer;
    }
  
    // 修改鼠标经过表格的颜色
    /deep/ .el-table tbody tr:hover > td {
      // 可以选择隐藏
      background-color: transparent !important;
      opacity: 0.8;
    }
  }
  
  /deep/ .el-dialog__body {
    padding: 0;
  }
  
  /deep/ .el-dialog__header {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
  }
  
  /deep/ .el-dialog__title {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    line-height: 32px;
    padding: 0;
  }
  
  /deep/.el-dialog__headerbtn {
    top: 8px;
  }
  
  /deep/.el-dialog__header {
    height: 32px;
    background: #1275d2;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
  }
  
  .dialog-div {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
  }
  
  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .canel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #0075f6;
  }
  
  .submit {
    cursor: pointer;
    margin-right: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 24px;
    background: #1275d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    line-height: 20px;
  }
  .submit-btn{
       margin-right: 0;
  }
  
  .dialog-box {
    padding-left: 24px;
  
    p {
      margin-top: 8px;
    }
  
    .qjsj {
      /deep/.el-input__inner {
        margin-top: 8px;
        width: 400px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #1275d2;
      }
    }
  
    /deep/ .el-icon-arrow-up:before {
      color: #1275d2;
    }
  }
  
  .div-text {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
    margin-top: 32px;
  }
  
  .search-box {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-left: 24px;
    padding-right: 15px;
    padding-top: 32px;
    padding-bottom: 36px;
  
    /deep/ .el-input {
      width: auto;
    }
  
    /deep/.el-input__inner {
      margin-top: 0;
      width: 240px;
      height: 32px;
      background: #ffffff;
      border-radius: 20px;
      border: 1px solid #d9d9d9;
    }
  
    /deep/ .el-input__icon {
      line-height: 32px;
    }
  
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 32px;
      background: #1275d2;
      border-radius: 4px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
    }
  }
  
  .del {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ff0000;
    line-height: 20px;
    text-decoration: underline;
  }
  
  .xuanze {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    line-height: 20px;
    text-decoration: underline;
  }
  
  .table-box {
    width: 772px;
    margin-left: 76px;
    margin-top: 40px;
  
    /deep/ .el-table {
      background: transparent;
  
      .el-table__header {
        background: transparent;
      }
  
      th {
        border-bottom: 2px #1a95eb solid;
      }
  
      th,
      tr {
        background-color: transparent;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 20px;
      }
    }
  }
  
  .blue-bg {
    background-color: #1275d2;
    color: #ffffff !important;
  }
  
  .detail-address {
    margin-left: 20px;
  }
  
  .detail {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    margin-left: 76px;
    display: flex;
    align-items: center;
  
    span {
      color: #ff0000;
    }
  }
  
  .radio-div {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 40px;
  
    div {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #666666;
      cursor: pointer;
      margin-right: 32px;
      padding: 0 20px;
      height: 28px;
      border-radius: 16px;
      border: 1px solid #1275d2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .radio-box {
  padding-top: 42px;
    margin-left: 76px;
    margin-bottom: 40px;
  }
  
  /deep/.upload-demo {
    c .el-upload-list {
      position: absolute;
      left: 50%;
      top: 225px;
      transform: translateX(-50%);
      width: 200px;
  
      .el-upload-list__item-status-label,
      .el-icon-close {
        top: -238px;
        right: -202px;
        opacity: 0;
        display: block;
      }
  
      .el-upload-list__item-status-label {
        .el-icon-upload-success {
          width: 604px;
          height: 300px;
        }
      }
  
      .el-icon-close {
        width: 604px;
        height: 300px;
      }
    }
  }
  
  .scwj {
    height: 550px;
    position: relative;
  }
  
  .wenjscbox {
    position: relative;
  
    left: 76px;
  
    .upFile {
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      .fileName {
        max-width: 160px;
        padding-right: 10px;
        height: 20px;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  
      .btn {
        cursor: pointer;
        color: red;
      }
    }
  }
  
  .wenjscbox {
    width: 600px;
  
    img {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
  
    .btn {
      display: flex;
      align-items: center;
      height: 20px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      line-height: 20px;
    }
  }
  
  .show-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0e, 0.5);
    z-index: 9999;
    top: 0;
    left: 0;
  
    & > div {
      position: absolute;
      background: #f9f9f9;
      width: 500px;
      height: 200px;
      left: 50%;
      top: 50%;
  
      div {
        margin-top: 50px;
  
        display: flex;
        align-items: center;
        justify-content: center;
  
        p {
          display: inline-block;
          max-width: 150px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
  
      transform: translate(-50%, -50%);
  
      .top {
        margin-top: 0;
        height: 20px;
        background: #333333;
      }
  
      .footer {
        position: absolute;
        bottom: 20px;
        display: flex;
        left: 50%;
        transform: translateX(-50%);
  
        & > div {
          margin-right: 50px;
          background: #1175d2;
          border-radius: 5px;
          padding: 5px;
          color: #f9f9f9;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
  .disabled {
    color: #666666;
  }
  .my-select {
    /deep/ .el-select-dropdown__list {
      padding-bottom: 20px;
    }
  }
  /deep/ .el-radio__inner{
      width: 20px;
      height: 20px;
  } 
  /deep/ .el-radio__input.is-checked .el-radio__inner{
           background: #fff !important;
            width: 20px;
        height: 20px;
       &::after{
              width: 10px;
  height: 10px;
  background: #1175D2;
  border-radius: 50%;
           }
      }
    .jkd{
      /deep/ .el-dialog__close{
         color: #ffffff;
      }
      /deep/.el-dialog__body{
          overflow: inherit;
      }
    }
  </style>
  <style lang="less" scoped>
      @media screen and (max-width: 1660px) {
          #particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
              transform: scale(0.8);
              margin: 0px;
          }
  
          #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
              transform: scale(0.8);
              margin: 0px;
              width: 140px;
              float: left;
              display: block;
          }
  
          #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
              width: 140px !important;
              // bottom: -36% !important;
              right: 2% !important;
          }
          #particulars-schedule /deep/ .el-dialog{
              margin-top: 18vh !important;
              transform: scale(0.9);
              height: 475px !important;
          }
          #particulars-schedule .el-dialog  .el-dialog__body .koko{
              margin-bottom: 20px !important;
          }
          
          #particulars-schedule .el-dialog  .el-dialog__body .wrap{
              height: 300px !important;
          }
      }
  
      @media screen and (max-width: 1360px) {
          #particulars-schedule /deep/ .el-dialog{
              transform: scale(0.8);
              height: 450px !important;
          }
          #particulars-schedule .el-dialog  .el-dialog__body .koko{
              margin-bottom: 20px !important;
          }
          
          #particulars-schedule .el-dialog  .el-dialog__body .wrap{
              height: 300px !important;
          }
      }
  </style>
  <style scoped lang="less">
  
  .dialog-box {
    padding-left: 24px;
  
    p {
      margin-top: 8px;
    }
  
    .qjsj {
      /deep/.el-input__inner {
        margin-top: 8px;
        width: 400px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #1275d2;
      }
    }
  
    /deep/ .el-icon-arrow-up:before {
      color: #1275d2;
    }
  }
  
  .detail {
      align-self: flex-start;
      margin-top: 32px;
      margin-left: 0;
    }
    .click-select {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-right: 35px;
    background: white;
    z-index: 10;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid #1275d2;
    &.active::after {
      top: 19%;
      border: 8px solid transparent;
      border-bottom: 8px solid #1275d2;
    }
    &::after {
      content: "";
      position: absolute;
      border: 8px solid transparent;
      border-top: 8px solid #1275d2;
      right: 9px;
      top: 40%;
    }
  }
  /deep/ .express {
    height: 448px;
  
    .el-input__inner {
      margin-left: 1px;
      margin-top: 1px;
      width: 300px;
      height: 64px;
      border-radius: 6px;
      border: transparent;
      color: transparent;
    }
  
    .edit-box {
      cursor: pointer;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      display: flex;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
    }
  
    .code {
      margin-top: 32px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #0075f6;
    }
    .my-select {
      /deep/ .el-select-dropdown__list {
        padding-bottom: 20px;
      }
  }
  
    .detail {
      align-self: flex-start;
      margin-top: 32px;
      margin-left: 0;
    }
  
    .text {
      margin-top: 2px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #666666;
    }
  }
  .code {
      margin-top: 32px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #0075f6;
    }
  .div-text {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    line-height: 20px;
    margin-top: 32px;
  }
    .jkd{
      /deep/ .el-dialog__close{
         color: #ffffff;
      }
      /deep/.el-dialog__body{
          overflow: inherit;
      }
    }
  .btn-box{
      display: flex;
        align-items: center;
      
        
       &>div{
          display: flex;
        align-items: center;
        color:#1275D2;
        font-size: 12px;
            margin-right: 25px;
        cursor: pointer;
          } 
        img{
          width: 13px;
          margin-right: 7px;
        }
  }
  .address{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
  }
  .show-box {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
  
    .logistics {
      width: 400px;
      height: 592px;
      background: #fff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  
      .title {
        height: 32px;
        background: #343b43;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 0 20px;
        margin-bottom: 16px;
        span:nth-of-type(2)::after {
          content: "X";
          cursor: pointer;
        }
      }
      .content {
        padding-left: 20px;
      }
      .item {
        display: flex;
  
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
  
        p:nth-of-type(1) {
          width: 64px;
        }
        p:nth-of-type(2) {
          width: 180px;
        }
  
        .icon-img {
          width: 16px;
          height: 16px;
          margin-left: 5px;
        }
      }
      .btn {
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        padding-bottom: 8px;
  
        border-bottom: #1275d2 solid 1px;
        & > div {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #1275d2;
          margin-right: 26px;
          img {
            margin-right: 2px;
          }
        }
      }
      .list {
            height: 415px;
             overflow: auto;
        .last-box {
          &::before {
            content: "";
             position: absolute;
             z-index: 99;
             left: 0;
            width: 10px;
            height: 10px;
            background: #bababa !important;
            border-radius: 50%;
          }
        }
        & > div {
          display: flex;
          
          & > div:nth-of-type(1) {
            width: 80px;
          
            font-size: 12px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #666666;
          }
          & > div:nth-of-type(2) {
  
              width: 230px;
              padding-bottom: 20px;
            display: flex;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #333333;
          
            position: relative;
            overflow: hidden;
            padding-left: 20px;
            .first {
            
            }
         
            .line {
              position: absolute;
              left: 5px;
  
              border-left: 1px dashed #0089ff;
          
              height: 100%;
            }
            &::before {
              position: absolute;
           left: 0;
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              background: #0089ff;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .planFlag {
          /deep/.el-button {
              background: #666 !important;
              border: none !important;
              span {
                  color: #fff !important;
              }
          }
      }
  a {
      text-decoration:none;
      color: #fda21c;
      &:hover {
          color: #333;
      }
  }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //针对ie
          #particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper{
              top:-7px;
          }
      }
      #particulars-schedule {
          overflow: auto;
  
          .el-timeline-item {
              height: 70px;
              padding-bottom: 34px;
  
              .el-timeline-item__dot {
                  .el-image {
                      margin-left: -8px;
                      width: 26px;
                      height: 26px;
                  }
              }
  
              /deep/.el-timeline-item__wrapper {
                  position: relative;
                  padding-left: 36px;
                  font-size: 16px;
              }
  
              /deep/ .el-timeline-item__tail {
                  border-left-style: dashed;
              }
  
              .line {
                  height: 0;
                  width: 94%;
                  position: absolute;
                  bottom: -34px;
                  right: 0;
                  border: 0.5px solid #d6d6d6;
              }
  
              /deep/.buttwozu {
                  // height:36px;
                  // width:100%;
                  position: absolute;
                  right: 2%;
                  bottom: 0%;
                  z-index: 1;
  
                  .el-button {
                      width: 140px;
                      height: 36px;
                      padding: 0;
                      line-height: 36px;
                      border: 1px solid #f99400;
                      box-sizing: border-box;
                      background: #f9f9f9;
  
                      span {
                          color: #fda21c;
                      }
  
                      // span:hover {
                      // 	color: #fff;
                      // }
                  }
  
                  // .el-button:hover {
                  //   background-color: red;
                  // }
  
                  .el-button:active {
                      // background-color:#1175d2;
                  }
              }
  
              .butonezu {
                  // height:36px;
                  // width:100%;
                  position: absolute;
                  right: 2%;
                  // margin-top:-15%;
                  bottom: 0%;
                  z-index: 1;
  
                  /deep/.el-button {
                      width: 140px;
                      height: 36px;
                      padding: 0;
                      line-height: 36px;
                      background: #f99400;
                      border: none;
  
                      span {
                          color: #fff;
                      }
                  }
              }
  
              .huyi2:hover {
                  background-color: #fda21c !important;
              }
  
              // .line:last-child{
              //     border:none;
              // }
          }
  
          /deep/ .el-dialog {
              width: 570px;
              height: 610px;
              // margin-top: 18vh !important;
              background: #f9f9f9;
              padding-left: 50px;
  
              .el-dialog__header {
                  padding: 25px 25px 0 40px;
  
                   .el-dialog__title {
                      font-weight: normal;
                      padding: 0;
                  }
  
                  .el-dialog__headerbtn {
                      top: 15px;
                      right: 17px;
                  }
  
                  .el-dialog__headerbtn .el-dialog__close {
                      width: 14px;
                      height: 14px;
                      color: #4b4b4b;
  
                  }
              }
  
              .el-dialog__body {
                  padding: 0;
                  text-align: center;
                  font-size: 16px;
  
                  .trackingNumber {
                      text-align: left;
                      margin-bottom:10px;
                  }
                  
                  .koko {
                      overflow: hidden;
                      margin-bottom: 48px;
  
                      .p1 {
                          margin-right: 60px;
                      }
                  }
  
                  .wrap {
                      height: 420px;
                      padding-right: 120px;
                      overflow: auto;
                      .el-timeline-item{
                          height: auto;
                      }
                      .el-timeline{
                          padding-top:10px ;
                      }
                      .el-timeline-item__wrapper{
                          width: 70%;
                          padding-left: 168px;
                          top:-12px;
                      }
                      .el-timeline-item__timestamp{
                          
                      }
                      .el-timeline-item__tail{
                          height:100%;
                          border-left-style: solid;
                          border-left-color: #999999;
                          left: 129px;
                          // top:22px;
                      }
                      .el-timeline-item__node--normal{
                          left: 120px;
                          width: 20px;
                          height: 20px;
                      }
                      .el-timeline-item__content,.el-timeline-item__timestamp{
                          text-align: left;
                      }
                      .el-timeline-item__timestamp.is-bottom{
                          margin-top: 5px;
                          color: #666 !important;
                      }
                      .time{
                          position: absolute;
                          left: 0;
                          top:0;
                          font-size: 16px;
                          
                      }
                      .date{
                              color: #333 !important;
                      }
                      .specificTime{
                          color: #666 !important;
                      }
                  }
              }
  
  
          }
      }
  </style>
  
  <style scoped lang="less">
      @media screen and (max-width: 1660px) {
          #particulars-schedule .el-timeline-item .butonezu .el-button {
              transform: scale(0.8) !important;
              margin: 0px !important;
          }
  
          #particulars-schedule .el-timeline-item .buttwozu .el-button {
              transform: scale(0.8) !important;
              margin: 0px !important;
              width: 140px !important;
              float: left !important;
          }
  
          #particulars-schedule .el-timeline-item .buttwozu {
              width: 140px !important;
              // bottom: -36% !important;
              right: 2% !important;
          }
      }
  
      @media screen and (max-width: 1360px) {}
  </style>
  <style lang="less" scoped>
  .el-timeline .el-timeline-item {
      position: relative;
  }
  .reminder {
      position: absolute;
      top: 0;
      left: 165px;
      width: 260px;
      height: 80px;
      padding: 15px;
      border-radius: 4px;
      box-shadow: 2px 2px 5px #aaa;
      justify-content: space-between;
      box-sizing: border-box;
      background: #fff;
      z-index: 5;
      .top,.bottom {
          font-size: 14px;
          color: #333;
          i {
              font-style: normal;
          }
          span:nth-of-type(1){
              color: #1175d2;
          }
          span:nth-of-type(2){
              margin-right: 26px;
          }
      }
      .bottom {
          margin-top: 10px;
          span:nth-of-type(1){
              margin-right: 13px;
          }
          span:nth-of-type(2){
              margin-right: 34px;
          }
      }
  }
  
  .reminderStyle {
      position: absolute;
      top: 3px;
      left: 138px;
      width: 20px;
      height: 20px;
  }
  </style>